import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button as GoButton } from '@fiverr-private/go_shared_ui';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import pathfinder from '@fiverr-private/pathfinder';
import { Text, Typography } from '@fiverr-private/typography';
import { Image } from '@fiverr-private/media';
import { theme } from '@fiverr-private/theme';
import { getContext } from '@fiverr-private/fiverr_context';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../../logged_out_homepage/utils/biEvents';
import { BQ_HOMEPAGE_CLICK } from '../../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';
import { MP_GO_BANNER_CLICKED } from '../../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { GO_LOGO_URL } from './constants';

export const Wrapper = () => {
  const { queryParameters } = getContext();
  const buttonUrl = pathfinder(
    'go_explore_landing_page',
    {},
    { query: { ...queryParameters, utm_source: 'lohp_banner', utm_medium: 'go_banner' } }
  );
  const isScreenSizeLessThanSm = useMediaQuery({ query: theme.breakpointsReverse.sm });
  const isScreenSizeLessThanMd = useMediaQuery({ query: theme.breakpointsReverse.md });

  const shouldUseOneLineTitle = isScreenSizeLessThanMd && !isScreenSizeLessThanSm;

  const onButtonClick = () => {
    sendBigQueryEvent({
      eventName: BQ_HOMEPAGE_CLICK,
      params: { elementName: 'fiverr_go_banner', elementType: 'button' },
    });

    sendMixPanelEvent({
      eventName: MP_GO_BANNER_CLICKED,
      params: {},
    });
  };

  return (
    <Stack direction="column" maxWidth="100%" width="100%" gap="4" position="relative" order={{ default: 2, md: 1 }}>
      <Container height={{ default: '19px', sm: '30px' }}>
        <Image maxWidth="fit-content" maxHeight="100%" alt="Fiverr Go" src={GO_LOGO_URL} />
      </Container>
      <Typography
        as="h2"
        color="white"
        size={{ default: 'h_xl', sm: 'h_xxl', md: 'h_3xl', lg: 'h_5xl', xl: 'h_5xl' }}
        style={{ fontFamily: 'PowerGrotesk', fontWeight: 300, lineHeight: '100%' }}
        textAlign="start"
      >
        <I18n
          k={
            shouldUseOneLineTitle
              ? 'logged_out_homepage.go_hero.title_top_talent'
              : 'logged_out_homepage.go_hero.title_top_talent_with_br'
          }
        />
      </Typography>
      <Text size="b_md" color="white" textAlign="start">
        <I18n k="logged_out_homepage.go_hero.get_what_you_need" />
      </Text>
      <Container textAlign={{ default: 'center', md: 'start' }}>
        <GoButton
          href={buttonUrl}
          onClick={onButtonClick}
          target="_blank"
          // todo: expose types from go_shared_ui package correctly
          // eslint-disable-next-line
          // @ts-ignore
          borderRadius="lg"
          size="lg"
          fullWidth={isScreenSizeLessThanSm}
        >
          <I18n k="logged_out_homepage.go_hero.get_started" />
        </GoButton>
      </Container>
    </Stack>
  );
};
